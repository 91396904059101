import { Link } from "react-router-dom";
import Header from './header';


function Software () {

  return(
    <div id="background-style-home">
    <div className="app">
      <Header />
      {/* Your other components */}
    </div>
    <div id='contact-page-update'>
      <div>
        <header>
          <h1 className="web-header">
            Software
          </h1>
        </header>
      </div>
      <div className="software-style">
        <h2>Expert Software Engineering: Turning Your Vision into Reality</h2>
        <p>
          In today's rapidly evolving digital landscape, bringing innovative software 
          ideas to life can pose challenges, especially without the time or technical 
          know-how for coding. My focus lies in creating custom software solutions, 
          bridging the gap between concept and reality without the need for extensive 
          coding knowledge. Whether you envision an e-commerce platform or a data-driven 
          application, I'll guide you through the development process, emphasizing 
          teamwork and transparency every step of the way.
        </p>
        <p>
        When it comes to deploying software, setting up servers and configuring them 
        properly are crucial. I offer personalized solutions, giving you the flexibility 
        to either set up your own servers or utilize my hosting infrastructure. This 
        approach ensures that your decisions are well-informed and aligned with your 
        budget and scalability requirements.
        </p>

        The user interface is key to engaging your audience effectively. I specialize 
        in tailoring interfaces to suit your unique needs, whether it's creating 
        custom designs from scratch or offering the option to choose from a selection 
        of templates. My priority is to ensure that the interfaces I craft are not 
        only intuitive and visually appealing but also resonate with your target 
        users, fostering meaningful interactions and user satisfaction.
        <p>

        Together, we'll work hand in hand to transform your software concepts into 
        practical solutions that fuel business expansion and user fulfillment. If you 
        have a software concept ready to come to life, let's team up and bring it to fruition.
        </p>
      </div>
      <div>
        <div>
        <Link to="/" > 
          <button className='buttons'>HOME</button>
        </Link>
      </div>
      <div>
        <Link to="/contact">
          <button className='button-info'>CONTACT</button>
        </Link>
        </div>
      </div>
    </div>
      <div>
      <footer className='web-footer'>
        <div >
          <Link to="/" className='social-style'>
          GOODMAN CONSULTING
          </Link>
        </div>
      </footer>
      </div>
    </div>
  )

}

export default Software