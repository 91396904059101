
import { Link } from "react-router-dom";
import Header from './header';



function Home() {
  return (
    <div id="background-style-home">
      <div className="app">
      <Header />
      {/* Your other components */}
      </div>
      <div id='container-info'>
        <div className='background-img' alt="succesful website and logo designs serving the wenatchee valley">
          <h1 className='text-color'>
          <div>
          <div>
          Crafting Digital Success
          </div>
          <div> 
          for Small Businesses: 
          </div>
          <div>
          Where Vision Meets Digital Design
          </div>
          </div>
          </h1>
        </div>
        <div className="website-box-style">
          <h2>Expert Website Design for Improved Search Engine Visibility</h2>
          <p>
            Our objective is to cultivate strong relationships 
            with your business through strategic digital expansion.
            We specialize in delivering customized solutions that 
            precisely match your desired style and user experience, 
            ensuring seamless navigation and visually captivating 
            interfaces for both you and your customers.
          </p>
          <p>
            As your company evolves and expands, our commitment is 
            to evolve alongside you, enriching your digital presence 
            and providing tailored software solutions as required. We 
            aspire to serve as your comprehensive solution for all things 
            digital and hardware-related, ensuring seamless scalability and 
            continuous support throughout your journey of growth.
          </p>
        </div>
      </div>
          <footer className='web-footer'>
            <div >
              <Link to="/" className='social-style'>
              GOODMAN CONSULTING
              </Link>
            </div>
          </footer>
    </div>
  );
}

export default Home;