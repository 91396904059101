import wenatchee from "../IMG/wenatchee_img.jpeg"
import { Link } from "react-router-dom";
import axios from 'axios';
import React, { useState } from 'react';
import Header from './header';

function Contact () {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://api.goodmanconsultingwa.com/api/send', formData);
      alert('Email sent successfully');
      setFormData({ name: '', email: '', phone: '' });
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email');
    }
  };

  return(
    <div id="background-style-contact">
      <div className="app">
        <Header />
        {/* Your other components */}
      </div>
    <div id='contact-page-update'>
          <div>
            <img src={wenatchee} alt="" className="contacts-header img-fluid"/>
          </div>
        <div className="contact-form-fill">
          <div className="d-flex">
            <ul className="contact-container">
              <li className="contacts">
                Location: Wenatchee, WA
              </li>
              <li className="contacts">
                Phone: <a href="tel:+15096993635" className="contact-links">(509) 699-3635</a>
              </li>
              <li className="contacts">
                Email: <a href="mailto:goodmanconsultingwa@gmail.com" className="contact-links">goodmanconsultingwa@gmail.com</a>
              </li>
              <li className="contacts">
                Hours: 9am to 7pm
              </li>
            </ul>
          </div>
          <div>
            <form onSubmit={handleSubmit}  className="contact-email">
              <div>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
                className="email-input"
              />
              </div>
              <div>
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
                className="email-input"
              />
              </div>
              <div>
              <input
                type="tel"
                name="phone"
                placeholder="Your Phone"
                value={formData.phone}
                onChange={handleChange}
                required
                className="email-input"
              />
              </div>
            <div className="d-flex flex-column align-itmes-center">
              <div>
                <button type="submit" className='button-info'>Submit</button>
              </div>
              <div>
                <Link to="/" > 
                  <button className='buttons'>HOME</button>
                </Link>
              </div>
          </div>
            </form>
            </div>
        </div>
        <div>
          <footer className='web-footer'>
            <div >
              <Link to="/" className='social-style'>
              GOODMAN CONSULTING
              </Link>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );


}

export default Contact