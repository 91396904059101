import React, { useState, useEffect } from 'react';
import logo from "../IMG/gman.png";
import SlidingMenu from '../components/slidingmenu';





const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 871);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      const newIsSmallScreen = window.innerWidth <= 871;

      // Check if the screen size crossed the 871px mark
      if (isSmallScreen !== newIsSmallScreen) {
        window.location.reload(); // Refresh the page
      }

      setIsSmallScreen(newIsSmallScreen);
    };

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSmallScreen]);

  return (
    <div className='d-flex'>
    <div className={`header ${isScrolled ? 'hidden' : ''}`}>
        <div className='logo-style'>
          <div>
            <img src={logo} alt="" className='logo img-fluid' />
          </div>
          <div className='gm-style'>
            <div>
              <h2>Goodman</h2>
            </div>
            <div>
              <h3>Consulting</h3>
            </div>
          </div>
        </div>
        <div className={`hamburger-menu ${isSmallScreen ? 'visible' : ''}`}>
        {/* Render the hamburger button */}
        <button className={`hamburger ${isScrolled ? 'hidden' : ''}`} aria-label="Menu" onClick={toggleMenu}>&#9776;</button>
        {/* Render the sliding menu */}
        
      </div>
        {!isSmallScreen && (
          <div className={`buttons-container ${isScrolled ? 'hidden' : ''}`}>
            <a href="/" className="buttons">Home</a>
            <a href="/web-design" className="buttons">Web-Design</a>
            <a href="/software" className="buttons">Software Engineering</a>
            <a href="/contact" className="button-info">Contact</a>
          </div>
        )}
      </div>
          <div>
          <SlidingMenu isOpen={isMenuOpen} />
          </div>
    </div>
  );
};

export default Header;