import React from 'react';

import wireframe from '../IMG/consultingwireframe.png';
import { Link } from "react-router-dom";
import Header from './header';

function Webdesign() {

  return(
  <div id='background-style-home'>
    <div className="app">
      <Header />
      {/* Your other components */}
    </div>
    <div id='container-info'>
      <div className="web-header">
      <h1>Expert Web Design Services</h1>
      </div>
      <div className="description">
        <h2>Kickstarting Your Digital Presence with Premium Website Development</h2>
        <p>
          My objective is to bring your envisioned website to life. In instances where your vision may not be fully defined, I offer the expertise to craft preliminary wireframes. 
          These conceptual designs serve to provide a tangible preview, guiding you towards a clearer understanding of the potential final product.
        </p>
      </div>
      <div className='web-design'>
        <div>
          <img src={wireframe} alt="Professional Website Design Services - A team of experts local to wenatchee washington working on a website design project" className='img-fluid wireframe' />
        </div>
        <div className='description'>
          <p>
          After our consultation, I'll carefully gather the details we need and quickly share a handpicked set of 
          initial designs with you. If any option doesn't quite match what you have in mind, I'm dedicated to 
          fine-tuning the ideas until we capture the exact look and feel you want for your website. Then, we'll 
          move forward to bring your vision to life during the development phase.
          </p>
        </div>
      </div>
      <div>
        <Link to="/" > 
          <button className='buttons'>HOME</button>
        </Link>
      </div>
      <div>
        <Link to="/contact">
          <button className='button-info'>CONTACT</button>
        </Link>
      </div>
      <div>
        <footer className='web-footer'>
          <div >
            <Link to="/" className='social-style'>
            GOODMAN CONSULTING
            </Link>
          </div>

        </footer>
      </div>
    </div>
  </div>
  )
}

export default Webdesign