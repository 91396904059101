import React, { useEffect, useState } from 'react';



const SlidingMenu = ({ isOpen }) => {

  const [isScrolled, setIsScrolled] = useState(false);


useEffect(() => {
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

  return (
    <div className={`sliding-menu ${isOpen ? 'open' : ''} `}>
      <ul className={`nav ${isScrolled ? 'hidden' : ''}`}>
        <li><a href="/" className="buttons">Home</a></li>
        <li><a href="/web-design" className="buttons">Web-Design</a></li>
        <li><a href="/software" className="buttons">Software</a></li>
        <li><a href="/contact" className="button-info">Contact</a></li>
      </ul>
    </div>
  );
};

export default SlidingMenu;